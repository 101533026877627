import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { ControlledSelectGray, Input } from "components/Shared";
import { useDispatch } from "react-redux";
import { RouterSupportedDOCountry } from "interfaces/enums";
import { useGetUserQuery } from "api/institutional/user";
import { ChangeEvent } from "react";
import { Account } from "./AccountForm";
import { useGetRouterSubAccountsQuery } from "api/institutional/router_api";

import { IRouterCreateBankAcc, IRouterSubAccount } from "interfaces/router";
import { IUser } from "interfaces";
import { MexicanAccount } from "./MexicanAccountForm";

const Body = ({
  user,
  subAccounts,
  handleClose,
}: {
  user?: IUser;
  handleClose: any;
  subAccounts: Array<IRouterSubAccount>;
}) => {
  const { t } = useTranslation();
  const countryOptions = [
    { label: t("US"), value: RouterSupportedDOCountry.US },
    { label: t("MX"), value: RouterSupportedDOCountry.MX },
    { label: t("AU"), value: RouterSupportedDOCountry.AU },
    { label: t("HK"), value: RouterSupportedDOCountry.HK },
    { label: t("CN"), value: RouterSupportedDOCountry.CN },
    { label: t("CH"), value: RouterSupportedDOCountry.CH },
    { label: t("JP"), value: RouterSupportedDOCountry.JP },
    { label: t("NZ"), value: RouterSupportedDOCountry.NZ },
    { label: t("SG"), value: RouterSupportedDOCountry.SG },
  ];

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IRouterCreateBankAcc>();

  const country = watch("country");

  return (
    <>
      {/* Transfer Type */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Destination Country")}</p>

        <Controller
          control={control}
          name="country"
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              options={countryOptions}
            />
          )}
        />
      </div>

      {user?.isRouterBroker && (
        <div>
          <p className=" text-4 font-interSemiBold">{t("Client")}</p>
          <Controller
            control={control}
            name="sub_account_id"
            render={(renderProps) => (
              <ControlledSelectGray
                isClearable={true}
                options={subAccounts.map((s) => ({
                  label: s.client_name,
                  value: s.sub_account_id,
                }))}
                renderProps={renderProps}
              />
            )}
          />
        </div>
      )}

      {/* Recipient Name */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Recipient's Name")}</p>
        <Controller
          control={control}
          name="account_name"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              placeholder={t("Enter the full name of the recipient")}
              className="shadow-sm"
              bgColor="gray-light102"
              errors={errors}
              value={value}
              onBlur={(b: any) => {
                onChange(b.target.value.trim());
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e.target.value)
              }
            />
          )}
        />
      </div>
      {country === RouterSupportedDOCountry.MX ? (
        <MexicanAccount handleClose={handleClose} />
      ) : (
        <Account handleClose={handleClose} />
      )}
    </>
  );
};

export const AddBankAccountModalRouter = ({
  data,
}: {
  data: { country: RouterSupportedDOCountry };
}) => {
  const { t } = useTranslation();

  // APIs

  const handleClose = () => {
    dispatch(closeModal());
  };

  const dispatch = useDispatch();

  const { data: user } = useGetUserQuery({});

  const { data: subAccounts = [] } = useGetRouterSubAccountsQuery(undefined, {
    skip: !user?.isRouterBroker,
  });

  const methods = useForm<IRouterCreateBankAcc>({
    defaultValues: {
      account_name: "",
      country: data.country,
    },
  });

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Add New Destination Bank Account")}
        </span>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Body
            user={user}
            subAccounts={subAccounts}
            handleClose={handleClose}
          />
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankAccountModalRouter;
