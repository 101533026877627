import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { IRouterCreateBankAccUs } from "interfaces/router";
import React from "react";
import { AsyncDialogProps } from "react-dialog-async";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogData {
  title: string;
  message: string;
  values: IRouterCreateBankAccUs;
}

export const ConfirmAddress: React.FC<
  AsyncDialogProps<ConfirmationDialogData, boolean>
> = ({ open, handleClose, data }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle className="md:text-7 text-6 font-interSemiBold text-black">
        {data.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {data.message}:
          <p>
            {data.values.street_1 ?? ""} <p>{data.values.street_2 ?? ""}</p>
          </p>
          <p>
            {data.values.city}, {data.values.state}
          </p>
          <p>{data.values.postal_code}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>{t("Back")}</Button>
        <Button onClick={() => handleClose(true)} autoFocus>
          {t("Accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAddress;
