import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  Button,
  ControlledCreatableSelectGray,
  ControlledSelectGray,
  EmptyComponent,
  Input,
  errorSnack,
} from "components/Shared";
import { CreateDestinationBankAccountSnack } from "components/Shared/Snacks";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "react-tooltip";
import { IRouterCreateBankAcc } from "interfaces/router";
import { useCreateBankAccountRouterMutation } from "api/institutional/router_api";
import {
  RouterBankAccountTypes,
  ReapTransfers,
  RouterTransferMethods,
} from "interfaces/enums";
import { getBankOptions } from "utils/getBankOptions";

import { useDialog } from "react-dialog-async";
import ConfirmAddress from "../ConfirmAddress";
import { useEffect } from "react";

import {
  useFindTransferMethodsByFiatAndCountry,
  useGetFiatByCountry,
} from "hooks/useCurrencyForm";
import { valueLabelMapper } from "utils";

export const Account = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    watch,
    register,
    handleSubmit,
  } = useFormContext<IRouterCreateBankAcc>();

  const handleAddUSBank = async (values: IRouterCreateBankAcc) => {
    await addUsBankAccount({
      ...values,
      street_2: values.street_2 ? values.street_2 : null,
    }).unwrap();

    // Reset Fields
    reset();

    enqueueSnackbar(<CreateDestinationBankAccountSnack />);

    handleClose();
  };

  const confirmationDialog = useDialog(ConfirmAddress);

  const submitAddBankAccount = async (values: IRouterCreateBankAcc) => {
    try {
      await handleAddUSBank(values);
    } catch (e: unknown) {
      let error = e as { error: string; correction?: any };
      if (error?.error) {
        if (error.correction) {
          const response = await confirmationDialog.show({
            title: t("Error detected in address"),
            message: t("Corrected address"),
            values: error.correction,
          });
          if (response) {
            submitAddBankAccount({ ...values, ...error.correction });
          }
        } else {
          enqueueSnackbar(error.error, errorSnack);
        }
      } else {
        enqueueSnackbar(
          t("An error occurred. Please notifiy Ridian."),
          errorSnack,
        );
      }
    }
  };

  const country = watch("country");

  const fiat = watch("fiat");

  useEffect(() => {
    reset({
      ...getValues(),
      fiat: undefined,
      transfer_method: undefined,
      bank_name: undefined,
      account_number_type: undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const { destinations } = useGetFiatByCountry({ country });
  const transfer_method = watch("transfer_method");

  const { transferMethods } = useFindTransferMethodsByFiatAndCountry({
    fiat,
    country,
  });

  useEffect(() => {
    if (!ReapTransfers.includes(transfer_method)) {
      setValue("account_type", undefined);
    } else {
      setValue("bank_network_id", undefined);
      setValue("account_number_type", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer_method]);

  useEffect(() => {
    //@ts-ignore
    setValue("transfer_method", undefined);
    setValue("account_number_type", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiat]);

  const bankNameOptions = valueLabelMapper(getBankOptions(country));

  const [addUsBankAccount, { isLoading: isAddingBankAccountNonUs }] =
    useCreateBankAccountRouterMutation();

  const getNetworkIdName = () => {
    if (
      transfer_method === RouterTransferMethods.HK_CHATS ||
      transfer_method === RouterTransferMethods.SWIFT
    ) {
      return t("Swift Code");
    }
    if (transfer_method === RouterTransferMethods.HK_FPS) {
      return t("Bank Code");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitAddBankAccount)}
      className="gap-5 flex flex-col"
    >
      {/* Alias */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Alias")}</p>
        <Input
          type="text"
          placeholder={t("Alias")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("alias", {
            required: true,
            onBlur: () => setValue("alias", getValues("alias")?.trim()),
          })}
        />
      </div>

      <div>
        <p className=" text-4 font-interSemiBold">{t("FIAT")}</p>

        <Controller
          name="fiat"
          rules={{ required: !ReapTransfers.includes(transfer_method) }}
          control={control}
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              options={destinations}
            />
          )}
        />
      </div>

      {/* Transfer method */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Transfer Method")}</p>
        <Controller
          name="transfer_method"
          rules={{ required: true }}
          control={control}
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              options={transferMethods}
            />
          )}
        />
      </div>

      {/* Bank Name */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Bank Name")}</p>
        <Controller
          name="bank_name"
          rules={{ required: true }}
          control={control}
          render={(renderProps) => (
            <ControlledCreatableSelectGray
              options={bankNameOptions}
              renderProps={renderProps}
              formatCreateLabel={(prop: any) => `${t("Bank")}: ${prop}`}
            />
          )}
        />
      </div>

      {/* Account type */}
      <EmptyComponent value={!ReapTransfers.includes(transfer_method)}>
        <div>
          <p className=" text-4 font-interSemiBold">{t("Account Type")}</p>
          <Controller
            name="account_type"
            rules={{ required: !ReapTransfers.includes(transfer_method) }}
            control={control}
            render={(renderProps) => (
              <ControlledSelectGray
                renderProps={renderProps}
                options={[
                  RouterBankAccountTypes.CHECKING,
                  RouterBankAccountTypes.SAVINGS,
                ]}
              />
            )}
          />
        </div>
      </EmptyComponent>

      {/* Account Number type */}

      <div>
        <p className=" text-4 font-interSemiBold">{t("Account Number Type")}</p>
        <Controller
          name="account_number_type"
          rules={{ required: ReapTransfers.includes(transfer_method) }}
          control={control}
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              isClearable={true}
              options={
                [
                  RouterTransferMethods.HK_CHATS,
                  RouterTransferMethods.HK_FPS,
                ].includes(transfer_method)
                  ? ["ACCOUNT_NUMBER"]
                  : ["IBAN", "ACCOUNT_NUMBER"]
              }
            />
          )}
        />
      </div>

      {/* Number */}

      <div>
        <p className="text-4 font-interSemiBold">{t("Account Number")}</p>
        <Input
          type="text"
          placeholder={t("Enter the account number")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("account_number", {
            required: true,
            onBlur: () =>
              setValue("account_number", getValues("account_number")?.trim()),
          })}
        />
      </div>

      {/* Number */}
      <EmptyComponent value={!ReapTransfers.includes(transfer_method)}>
        <div>
          <p className="text-4 font-interSemiBold">{t("Routing Number")}</p>
          <Input
            type="text"
            placeholder={t("Enter the routing number")}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("routing_number", {
              required: !ReapTransfers.includes(transfer_method),
              onBlur: () =>
                setValue("routing_number", getValues("routing_number")?.trim()),
            })}
          />
        </div>
      </EmptyComponent>

      {/* bank_network_id */}
      <EmptyComponent value={ReapTransfers.includes(transfer_method)}>
        <div>
          <p className="text-4 font-interSemiBold">{getNetworkIdName()}</p>
          <Input
            type="text"
            placeholder={`${t("Enter the")} ${getNetworkIdName()}`}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("bank_network_id", {
              required: ReapTransfers.includes(transfer_method),
              onBlur: () =>
                setValue(
                  "bank_network_id",
                  getValues("bank_network_id")?.trim(),
                ),
            })}
          />
        </div>
      </EmptyComponent>

      {/* Number */}

      <div>
        <p className="text-4 font-interSemiBold">{t("Street 1")}</p>
        <Input
          type="text"
          placeholder={t("Enter the street 1")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("street_1", {
            required: true,
            onBlur: () => setValue("street_1", getValues("street_1")?.trim()),
          })}
        />
      </div>

      <div>
        <p className="text-4 font-interSemiBold">{t("Street 2")}</p>
        <Input
          type="text"
          placeholder={t("Enter the street 2")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("street_2", {
            onBlur: () =>
              setValue("street_2", getValues("street_2")?.trim() ?? ""),
          })}
        />
      </div>

      <div className="flex gap-4">
        <div className="flex-1">
          <p className="text-4 font-interSemiBold">{t("City")}</p>
          <Input
            type="text"
            placeholder={t("Enter the city")}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("city", {
              required: true,
              onBlur: () => setValue("city", getValues("city")?.trim()),
            })}
          />
        </div>

        <div className="flex-1">
          <p className="text-4 font-interSemiBold">{t("State")}</p>
          <Input
            type="text"
            placeholder={t("Enter the state")}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("state", {
              required: true,
              onBlur: () => setValue("state", getValues("state")?.trim()),
            })}
          />
        </div>
      </div>

      <div>
        <p className="text-4 font-interSemiBold">{t("Postal Code")}</p>
        <Input
          type="text"
          placeholder={t("Enter the postal code")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("postal_code", {
            required: true,
            onBlur: () =>
              setValue("postal_code", getValues("postal_code")?.trim()),
          })}
        />
      </div>

      <div className="flex gap-2">
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          type="submit"
          disabled={isAddingBankAccountNonUs || !isValid}
        >
          {isAddingBankAccountNonUs
            ? t("Adding Destination Account")
            : t("Add Destination Account")}
        </Button>
        <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
      </div>
    </form>
  );
};
