import { Button, FormControl, TextField } from "@mui/material";

import { IOnboardingDraft } from "interfaces";
import { useTranslation } from "react-i18next";

import { DynamicFormUnregulated } from "./DynamicFormUnregulated";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocument,
  forwardStep,
  GlobalState,
  setKYC,
} from "app/slices/globalSlice";

import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useUpdateOnboardingUnRegulatedMutation } from "api/institutional/onboarding";
import { useUploader } from "hooks/useUpload";

import { enqueueSnackbar } from "notistack";
import { errorSnack } from "components/Shared";
import { useEffect, useRef } from "react";
import { IDTypes } from "interfaces/enums";
import { ParticipantId } from "./ParticipantId";
import { useMergeFiles } from "./useMergeFiles";
import { UploadOrShow } from "./UploadOrShow";
import { removeUndefinedValues, useTrimmer } from "utils";
import { useKeyboardShortcut } from "hooks/useKeyboardShortcut";
import { RevenueForm } from "./Revenue";

const LegalRep = () => {
  const { control } = useFormContext<IOnboardingDraft>();
  const { onBlur } = useTrimmer();
  const { t } = useTranslation();
  return (
    <FormControl>
      <Controller
        control={control}
        name="legal_rep_name"
        rules={{ required: true }}
        render={({ field }) => (
          <TextField label={t("Full name")} {...field} onBlur={onBlur} />
        )}
      />
    </FormControl>
  );
};

export const NonRegulated = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { onboarding, step } = useSelector<any, GlobalState>(
    (state) => state.global,
  );

  const methods = useForm<IOnboardingDraft>({
    defaultValues: {
      legal_rep_name: "",
      id_type: onboarding.id_type || IDTypes.INE,
    },
    mode: "onBlur",
  });

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(
      `/kyb/document/${onboarding.id}`,
      files,
      category,
    );
    dispatch(addDocument({ filename, category }));
  };

  const { upload, error: uploadError, isLoading: isUploading } = useUploader();

  const [mutate] = useUpdateOnboardingUnRegulatedMutation();

  const legal_rep_name = methods.watch("legal_rep_name");

  const { getFiles } = useMergeFiles();

  const handleSend = async () => {
    const {
      corporate_shareholders,
      estimated_annual_revenue_range,
      estimated_monthly_volume_range,
      client_kyb_process,
      moves_client_funds,
      legal_rep_name,
      source_of_funds,
    } = methods.getValues();
    mutate(
      removeUndefinedValues({
        id: onboarding.id,
        company_name: onboarding.company_name.trim(),
        email: onboarding.email.trim(),
        company_category: onboarding.company_category.trim(),
        legal_rep_name: legal_rep_name?.trim() || "",
        corporate_shareholders,
        files: getFiles(corporate_shareholders),
        estimated_monthly_volume_range: estimated_monthly_volume_range?.trim(),
        estimated_annual_revenue_range: estimated_annual_revenue_range?.trim(),
        moves_client_funds: moves_client_funds,
        client_kyb_process: client_kyb_process?.trim(),
        website_url: onboarding.website_url?.trim(),
        business_description: onboarding.business_description?.trim(),
        source_of_funds,
      }),
    );
  };

  useEffect(() => {
    if (uploadError) {
      enqueueSnackbar(t("Error uploading KYB"), errorSnack);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const actaconstComplete = !!onboarding?.acta_constitutiva?.length;

  const constanciaFull =
    !!onboarding?.constancias_situacion_fiscal_sociedad?.length;

  const idcompleted = () => {
    if (
      onboarding.id_type === IDTypes.passport &&
      onboarding?.document_id?.length
    ) {
      return true;
    }
    if (
      onboarding.id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    ) {
      return true;
    }
    return false;
  };

  useKeyboardShortcut({
    key: "Enter",
    onKeyPressed: () => {
      step1Btn.current?.click();
      step2Btn.current?.click();
      step4Btn.current?.click();
    },
  });

  const handleNext = () => {
    dispatch(setKYC({ legal_rep_name }));
    dispatch(forwardStep());
  };

  const step1Btn = useRef<HTMLButtonElement>(null);
  const step2Btn = useRef<HTMLButtonElement>(null);
  const step3Btn = useRef<HTMLButtonElement>(null);
  const step4Btn = useRef<HTMLButtonElement>(null);

  const steps = () => {
    if (step === 1) {
      return (
        <>
          <span className="font-semibold text-3">
            {t("Legal Representative")}
          </span>

          <span className="text-3 text-[#737373]">
            {t("Who is acting as Legal Representative?")}
          </span>
          <LegalRep />
          <Button
            variant="contained"
            disabled={(legal_rep_name || "").split(" ").length <= 1}
            onClick={() => dispatch(forwardStep())}
            ref={step1Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }

    if (step === 2) {
      return <RevenueForm />;
    }
    if (step === 3) {
      return (
        <>
          <ParticipantId />
          <Button
            variant="contained"
            disabled={!idcompleted()}
            onClick={handleNext}
            ref={step3Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }

    if (step === 4) {
      return (
        <>
          <span className="font-semibold text-3">
            {t(
              "Certificate of Incorporation (and documents of share movements, if applicable)",
            )}
          </span>

          <span className="text-3 text-[#737373]">
            {t("Add a simple copy as an image or PDF")}
          </span>
          <UploadOrShow
            isUploading={isUploading}
            multiUpload={true}
            category={"acta_constitutiva"}
            doDrop={doDrop}
            files={onboarding?.acta_constitutiva}
            label={
              onboarding?.acta_constitutiva?.length
                ? t("Other documents")
                : t("Certificate of Incorporation")
            }
          />
          <Button
            variant="contained"
            disabled={!actaconstComplete}
            onClick={() => dispatch(forwardStep())}
            ref={step4Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }
    if (step === 5) {
      return (
        <>
          <span className="font-semibold text-3">
            {t("Company’s Tax Situation Certificate (no older than 3 months)")}
          </span>

          <span className="text-3 text-[#737373]">
            {t("Add a simple copy as an image or PDF")}
          </span>

          <UploadOrShow
            isUploading={isUploading}
            multiUpload={false}
            category={"constancias_situacion_fiscal_sociedad"}
            doDrop={doDrop}
            files={onboarding?.constancias_situacion_fiscal_sociedad}
            label={t("Tax Situation Certificate")}
          />

          <Button
            variant="contained"
            disabled={!constanciaFull}
            onClick={() => dispatch(forwardStep())}
            ref={step4Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }
    if (step === 6) {
      return (
        <>
          <span className="font-semibold text-3">
            {t("Shareholder information (with 25% or more participation)")}
          </span>

          <DynamicFormUnregulated onboardingId={onboarding.id} />
        </>
      );
    }
  };
  return (
    <FormProvider {...methods}>
      {steps()}
      {step === 6 && (
        <Button
          variant="contained"
          disabled={
            !methods.formState.isValid ||
            !legal_rep_name ||
            !actaconstComplete ||
            isUploading ||
            !idcompleted() ||
            !constanciaFull
          }
          onClick={handleSend}
        >
          {t("Send")}
        </Button>
      )}
    </FormProvider>
  );
};
