import { closeModal } from "app/slices/modalSlice";
import Modal from "../modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  CurrencyBody,
  Grid,
  LoadingComponent,
} from "components/Shared";
import { useDispatch, useSelector } from "react-redux";
import { Locations, SpreadTargetType } from "interfaces/enums";
import { useNavigate } from "react-router-dom";
import {
  useCreateRouterOrderMutation,
  useGetRouterQuoteMutation,
  useGetRouterSubAccountsQuery,
} from "api/institutional/router_api";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import useQuoteErrorListener, {
  useErrorListener,
} from "hooks/useErrorListener";
import BigNumber from "bignumber.js";
import { IRouterQuoteForm, IRouterQuoteResponse } from "interfaces/router";
import { PresetsState } from "app/slices/presetsSlice";

const ttl_seconds = 13;

export const ConfirmQuoteBrokerRouterModal = ({
  data,
}: {
  data: IRouterQuoteForm;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [getQuote, { isLoading: isLoadingGetQuote, error: errorGetQuote }] =
    useGetRouterQuoteMutation();

  // APIs
  const [
    createOrder,
    { isLoading: isLoadingCreateRioOrder, error: errorCreateOrder },
  ] = useCreateRouterOrderMutation();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const { spreadTargetType } = useSelector<any, PresetsState>(
    (state) => state.presets,
  );

  const [quote, setQuote] = useState<IRouterQuoteResponse>();

  const handleRefreshQuoute = async () => {
    const _quote = await getQuote({
      ...data,
      quote_target:
        spreadTargetType === SpreadTargetType.Rate
          ? BigNumber(1)
              .dividedBy(data.quote_target ?? 0)
              .toFormat()
          : undefined,
    }).unwrap();

    setQuote({
      ..._quote,
      date_expires_local: new Date().setSeconds(
        new Date().getSeconds() + ttl_seconds,
      ),
    });
  };

  useQuoteErrorListener({ error: errorGetQuote || errorCreateOrder });

  useEffect(() => {
    handleRefreshQuoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptOrder = async () => {
    try {
      const { sub_account_id, destination_account_id } = data;

      if (!quote?.id || !destination_account_id) return;
      const res = await createOrder({
        quote_id: quote.id,
        destination_account_id,
        sub_account_id: sub_account_id!,
      }).unwrap();

      if (res?.id) {
        handleClose();
        navigate(
          Locations["/ccy-conversion/order-router/:id"].replace(":id", res.id),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isRefetching = isLoadingGetQuote || isLoadingCreateRioOrder;

  const clientRate =
    quote?.rate_client && BigNumber(1).dividedBy(quote.rate_client);

  const refetching = () => {
    if (isRefetching) {
      return <>{t("Refreshing Quote")}...</>;
    }

    return (
      <>
        {t("Confirm")} (
        <Countdown
          key={quote?.id}
          date={quote?.date_expires_local}
          intervalDelay={0}
          precision={2}
          renderer={(props) => (
            <span className="text-4">
              {props.formatted.minutes}:{props.formatted.seconds}
            </span>
          )}
          onComplete={handleRefreshQuoute}
        />
        )
      </>
    );
  };

  const { sub_account_id } = data;

  const {
    data: subAccounts = [],
    error,
    isLoading: isLoadingSubAccounts,
  } = useGetRouterSubAccountsQuery(undefined, { skip: !sub_account_id });

  useErrorListener({ error });

  const body = () => {
    if (errorGetQuote || errorCreateOrder) {
      return <Modal.Body>{errorGetQuote || errorCreateOrder}</Modal.Body>;
    }

    return (
      <>
        <Modal.Body>
          <Grid>
            {[
              {
                label: t("Client"),
                component: isLoadingSubAccounts
                  ? t("Loading")
                  : subAccounts.find((s) => s.sub_account_id === sub_account_id)
                      ?.client_name,
              },
              {
                label: t("Source CCY"),
                component: data.ccy_source,
              },
              {
                label: t("Destination CCY"),
                component: data.ccy_dest,
              },

              {
                label: t("Source Quantity"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody symbol={quote?.ccy_source}>
                        {quote?.qty_source ?? 0}
                      </CurrencyBody>
                      <span> {data.ccy_source}</span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Destination Quantity"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody symbol={quote?.ccy_dest}>
                        {quote?.qty_dest ?? 0}
                      </CurrencyBody>
                      <span> {data.ccy_dest}</span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Cost Basis"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody isExchangeRate symbol={data.ccy_source}>
                        {BigNumber(1).dividedBy(BigNumber(quote?.rate_quote!))}
                      </CurrencyBody>
                      <span>
                        {" "}
                        {data.ccy_dest}/{data.ccy_source}
                      </span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Client Exchange Rate"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <span className="badge-COMPLETE px-1 py-1 rounded-sm">
                        <CurrencyBody isExchangeRate symbol={data.ccy_source}>
                          {clientRate ?? 0}
                        </CurrencyBody>
                      </span>
                      <span>
                        {" "}
                        {data.ccy_dest}/{data.ccy_source}
                      </span>
                    </div>
                  </LoadingComponent>
                ),
              },
            ]}
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex gap-4 justify-center">
            <Button
              className="w-50 bg-transparent text-gray-dark500 hover:bg-gray hover:text-white border border-gray rounded-full text-4 py-2 px-8 justify-center items-center w-50"
              onClick={handleClose}
              disabled={isLoadingCreateRioOrder}
            >
              {t("Cancel")}
            </Button>

            <Button
              className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 w-full items-center block"
              type="submit"
              onClick={handleAcceptOrder}
              disabled={isRefetching}
            >
              {isLoadingCreateRioOrder ? t("Confirming Quote") : refetching()}
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="flex items-center md:text-7 text-6 font-interSemiBold text-black">
          {t("Confirm Quote")}
        </span>
      </Modal.Header>
      {body()}
    </Modal>
  );
};

export default ConfirmQuoteBrokerRouterModal;
