import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button } from "@mui/material";
import useErrorListener from "hooks/useErrorListener";
import { useAcceptTermsMutation } from "api/institutional/user";

import SignUpLangSelector from "components/SignUpLangSelector";

const PolicyES = () => {
  return (
    <div className="overflow-auto h-[600px] text-justify">
      <div>
        <p className="pb-4">
          A fin de dar cumplimiento con lo establecido en la Ley Federal de
          Protección de Datos Personales en Posesión de los Particulares, su
          Reglamento y Lineamientos aplicables (la &quot;Ley&quot;), Envia
          Fondos, S.A.P.I. de C.V. (&quot;Ridian&quot;), con domicilio en Bosque
          de Chapultepec, calle Pedregal 24, Colonia Molino del Rey, C.P. 11000,
          CDMX, México (el &quot;Domicilio&quot;), dirección electrónica:
          https://ridian.io/ (el &quot;Sitio&quot;), titular de los derechos de
          la plataforma digital denominada &quot;Ridian&quot; (la
          &quot;Plataforma&quot;) y demás plataformas presentes y futuras de su
          propiedad, y con correo electrónico de contacto contact@ridian.io (el
          &quot;Correo Electrónico&quot;), pone a su disposición el presente:
        </p>
        <p className="py-4 pt-8 font-semibold">AVISO DE PRIVACIDAD</p>
        <p className="pb-4">
          Con la finalidad de dar un tratamiento legítimo, controlado e
          informado a los datos personales de los usuarios del Sitio y de la
          Plataforma (los &quot;Usuarios&quot;) y de los Usuarios que decidan
          crear una cuenta y contratar alguno de los servicios de Ridian (los
          &quot;Clientes&quot;), así como para garantizar su privacidad y su
          derecho a la autodeterminación informativa al proporcionarnos dichos
          datos, le informamos que Ridian es el responsable del tratamiento y
          protección de sus datos personales los cuales serán tratados con base
          en los principios de licitud, consentimiento, información, calidad,
          finalidad, lealtad, proporcionalidad y responsabilidad previstos en la
          Ley.
        </p>
        <p className="pb-4">
          Cuando Ridian deba tratar los datos personales de los Usuarios y
          Clientes a través de sus empresas controladoras y/o empresas filiales
          y/o subsidiarias y/o partes relacionadas (los &quot;Terceros
          Relacionados&quot;) y/o aquellos terceros que, por la relación
          comercial con Ridian hayan contratado el uso de la Plataforma para
          complementar su propuesta de negocios, siempre les dará a conocer el
          contenido del presente Aviso de Privacidad.
        </p>
        <p className="py-4 pt-8 font-semibold">
          DATOS PERSONALES QUE RECABAMOS
        </p>
        <p className="pb-4">
          Ridian solicita y obtiene los datos personales de sus Usuarios y
          Clientes (en lo sucesivo &quot;Datos Personales&quot;) a través del
          acceso, registro y creación de perfil de Usuario en el Sitio y/o en la
          Plataforma, y/o mediante correo electrónico, y/o llenado de
          formularios o encuestas físicas o electrónicas, según corresponda a
          los servicios.
        </p>
        <p className="pb-4">
          En todos los casos, la recolección de Datos Personales por parte
          Ridian es realizada de buena fe y para los fines aquí expuestos; por
          tal motivo, se presume que los Datos Personales proporcionados por sus
          titulares son apegados a la verdad y completos, por lo que son
          responsabilidad del titular que los proporciona.
        </p>
        <ul className="pb-4">
          <li className="ps-10">
            <ol className="">
              A. Los únicos Datos Personales que serán recabados de los Usuarios
              cuando hacen uso del Sitio y/o de la Plataforma constan de su
              Dirección de IP (Internet Protocol) de su dispositivo electrónico
              y de la URL de la que provienen las búsquedas de los Usuarios
              (estén o no en el Sitio), a qué URL acceden seguidamente (estén o
              no en el Sitio), qué navegador están usando, las páginas
              visitadas, las búsquedas realizadas, las publicaciones llevadas a
              cabo, las preferencias comerciales manifestadas y los mensajes
              publicados.
            </ol>
            <ol>
              B. Los Datos Personales que serán recabados de los Clientes para
              poder prestar los servicios dispuestos en el Sitio y/o en la
              Plataforma (los &quot;Servicios&quot;) constan de los siguientes:
              <ol className="list-decimal ps-10">
                <li>Nombre completo; </li>
                <li>Dirección de correo electrónico; </li>
                <li>Número de teléfono fijo y móvil; </li>
                <li>Dirección física; </li>
                <li>Los contenidos en su identificación oficial; </li>
                <li>Datos de su(s) cuenta(s) bancaria(s); </li>
                <li>
                  Información contenida en su constancia de situación fiscal.
                </li>
              </ol>
            </ol>
          </li>
        </ul>
        <p className="pb-4">
          Asimismo, le informamos que no recabamos Datos Personales sensibles ni
          de los Usuarios ni de los Clientes.
        </p>
        <p className="py-4 pt-8 font-semibold">
          FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES
        </p>
        <p className="py-4 pt-8 font-semibold">DE LOS USUARIOS</p>
        <p className="pb-4">
          a) Para consolidar el catálogo de trámites y Servicios que oferta
          Ridian a los Usuarios conjuntamente con sus socios comerciales a
          través de la Plataforma. b) Para ayudar a diagnosticar problemas con
          el Sitio y la Plataforma y poderlos resolver. c) Para dar cabal
          cumplimiento a leyes, reglamentos y lineamientos aplicables, políticas
          internas, procedimientos y obligaciones legales aplicables a Ridian,
          el Sitio y la Plataforma.
        </p>
        <p className="py-4 pt-8 font-semibold">DE LOS CLIENTES</p>
        <p className="pb-4">
          Una vez que un Usuario decida contratar alguno de los Servicios de
          Ridian, se convertirá automáticamente en un Cliente y Ridian tratará
          sus Datos Personales para llevar a cabo las siguientes finalidades que
          dan origen y son necesarias para la existencia, mantenimiento y
          cumplimiento de la relación jurídica entre ambos:
        </p>
        <p className="pb-4">
          <ol>
            <li>a) Para crear su perfil y cuenta como &quot;Cliente&quot;. </li>
            <li>
              b) Documentar la relación entre las partes, el proceso de uso y
              selección que realice o vaya a realizar el Cliente de los módulos
              que conforman los Servicios de la Plataforma.{" "}
            </li>
            <li>
              c) Para crear un registro de los Clientes y de los Servicios que
              utilicen, administren o gestionen a través de la Plataforma.{" "}
            </li>
            <li>
              d) Para proveer los Servicios de forma directa o a través de sus
              Terceros Relacionados o a través de aquellos terceros que, por la
              relación comercial que tengan con Ridian, hayan contratado el uso
              de la Plataforma para complementar su propuesta de negocios.{" "}
            </li>
            <li>
              e) Para consolidar el catálogo de trámites y Servicios que oferta
              Ridian al Cliente conjuntamente con sus socios comerciales a
              través de la Plataforma.{" "}
            </li>
            <li>
              f) Para llevar el registro y seguimiento de la relación comercial
              y jurídica que existe con los socios comerciales de Ridian que
              ofertan Servicios en la Plataforma al Cliente.{" "}
            </li>
            <li>g) Para comunicarse con los Clientes. </li>
            <li>
              h) Para ayudar a diagnosticar problemas con el Sitio y la
              Plataforma y poderlos resolver.{" "}
            </li>
            <li>
              i) Para dar cabal cumplimiento a leyes, reglamentos y lineamientos
              aplicables, políticas internas, procedimientos y obligaciones
              legales aplicables a Ridian, el Sitio y la Plataforma.
            </li>
          </ol>
        </p>
        <p className="py-4 pt-8 font-semibold">
          TANTO DE LOS USUARIOS COMO DE LOS CLIENTES
        </p>
        <p className="pb-4">
          Si usted no se opone a este tratamiento, Ridian podrá tratar sus Datos
          Personales para llevar a cabo alguna o todas las finalidades
          secundarias que se mencionan a continuación:
        </p>
        <p className="pb-4">
          <ol>
            <li>
              a) Para generar indicadores de datos y que Ridian pueda tomar
              decisiones pertinentes a su negocio.{" "}
            </li>
            <li>b) Para mejorar, desarrollar y perfeccionar los Servicios. </li>
            <li>
              c) Hacer uso de la tecnología &quot;push&quot; a través de la
              aplicación que Ridian usa para enviar notificaciones con
              autorización previa del Usuario o Cliente.{" "}
            </li>
            <li>
              d) Para fines mercadológicos, publicitarios o de prospección
              comercial.
            </li>
          </ol>
        </p>
        <p className="pb-4">
          Es importante mencionarle que Ridian solo utilizará sus Datos
          Personales para las finalidades secundarias previamente descritas, si
          usted las consintió. En caso de que no desee que sus datos personales
          sean tratados para estas finalidades secundarias favor de enviar un
          correo electrónico al Correo Electrónico disponible aquí
          contact@ridian.io señalando su negativa para el uso de sus Datos
          Personales para la(s) finalidad(s) secundaria(s) que deseé limitar. Si
          usted no manifiesta su negativa para el tratamiento de sus Datos
          Personales de conformidad con lo anterior, se entenderá que ha
          otorgado su consentimiento para el tratamiento de los mismos.
        </p>
        <p className="pb-4">
          La negativa para el uso de sus Datos Personales para estas finalidades
          no será motivo para que le neguemos los Servicios que solicita o
          contrata con Ridian en el Sitio y la Plataforma.{" "}
        </p>

        <p className="pb-4">
          Una vez cumplidas las finalidades del tratamiento de sus Datos
          Personales, y cuando no exista disposición legal que establezca lo
          contrario, Ridian procederá a la cancelación, eliminación y/o
          destrucción de los Datos Personales obtenidos, en los términos
          establecidos por la Ley.
        </p>

        <p className="py-4 pt-8 font-semibold">
          USO DE COOKIES Y TECNOLOGÍAS SIMILARES
        </p>

        <p className="pb-4">
          Ridian le informa que, mediante el uso de tecnologías, busca
          garantizar la mejor experiencia posible en el Sitio y/o la Plataforma,
          al proporcionarle información personalizada, recordando sus
          preferencias de Servicios y de mercadeo y ayudándolo a obtener la
          información adecuada. En virtud de lo anterior, Ridian le informa que
          recaba Datos Personales a través de cookies y de balizas web (a veces
          llamadas &quot;píxeles de seguimiento&quot; o &quot;GIF
          transparentes&quot;).
        </p>
        <p className="py-4 pt-8 font-semibold">COOKIES </p>
        <p className="pb-4">
          Las cookies son archivos de datos que se almacenas en el disco duro
          del equipo de cómputo o del dispositivo de comunicaciones electrónicas
          de un usuario al navegar en un sitio de internet específico, el cual
          permite intercambiar información de estado entre dicho sitio y el
          navegador del usuario. La información de estado puede revelar medios
          de identificación de sesión, autenticación o preferencias del usuario,
          así como cualquier dato almacenado por el navegador respecto al sitio
          de internet. Ridian y sus proveedores de servicios podrán hacer uso de
          uno o más tipos de cookies, en adelante descritas:
          <p className="py-4 font-semibold"> Cookies esenciales.</p>
          Requeridas para mejorar el funcionamiento del Sitio y de la
          Plataforma, permitiendo al Usuario y al Cliente recorrer el Sitio y la
          Plataforma y hacer uso de sus características (por ejemplo, para
          permitirle iniciar sesión en áreas seguras del Sitio o utilizar un
          carro de compras en la Plataforma). La deshabilitación de estas
          cookies provocará un entorpecimiento del desempeño del Sitio y la
          Plataforma, y posiblemente dejará sin disponibilidad las
          características del Sitio, la Plataforma y sus Servicios.
          <p className="py-4 font-semibold">
            Cookies de análisis y personalización.
          </p>{" "}
          Diseñadas para analizar la actividad del Usuario y el Cliente en el
          Sitio y/o en los sitios donde Ridian publique o promocione contenidos,
          mismas que se usan para asegurar que el Usuario y el Cliente puedan
          encontrar la información que buscan en el Sitio de una manera más
          fácil. Estas cookies permiten el reconocimiento y contabilización de
          visitantes al Sitio, así como la medición del desempeño de campañas
          publicitarias y optimización del contenido del Sitio por parte Ridian.
          <p className="py-4 font-semibold"> Cookies de funcionalidad. </p>
          Utilizadas para reconocer al Usuario y al Cliente cuando ingresan o
          regresan al Sitio o la Plataforma, mediante la personalización del
          contenido, uso del nombre registrado como Cliente y preferencias
          particulares de idioma o región.
          <p className="py-4 font-semibold"> Cookies de publicidad. </p>
          Estas cookies son utilizadas con el objeto de resaltar el contenido
          del Sitio que sea más relevante para el Usuario y el Cliente, en
          función de los intereses aparentes recopilados según su actividad en
          línea, incluidas las visitas al Sitio y la Plataforma, las páginas que
          ha visitado y la selección de enlaces y anuncios. Lo anterior permite
          que, mediante el uso de plataformas de administración y edición de
          datos, se enlacen avisos publicitarios en el Sitio propiedad Ridian o
          en cualquier otro, con la finalidad de mantener una promoción continua
          de los productos y servicios visitados en el Sitio.
        </p>
        <p className="py-4 pt-8 font-semibold">BALIZAS WEB</p>
        <p>
          Las balizas web (a veces llamadas &quot;píxeles de seguimiento&quot; o
          &quot;GIF transparentes&quot;) son archivos gráficos, muy pequeños,
          que contienen un identificador único que permite reconocer la visita
          al Sitio y Plataforma o notificar a Ridian cuando el Cliente abra un
          correo electrónico que haya sido enviado por Ridian. Generalmente,
          estas tecnologías se sustentan en cookies para que funcionen de manera
          correcta, por lo que su disminución afecta directamente su
          funcionamiento. Estas tecnologías podrán deshabilitarse de acuerdo con
          lo señalado en la configuración sobre uso de cookies del navegador o
          servicio de internet que usted utiliza. No obstante lo anterior, le
          informamos que, el bloqueo por parte del Usuario o del Cliente de
          todas las cookies, puede provocar el bloqueo o afectación total o
          parcial en el acceso y uso del Sitio y de la Plataforma.
        </p>
        <p className="py-4 pt-8 font-semibold">
          TRANSFERENCIAS DE LOS DATOS PERSONALES
        </p>
        <p>
          Sus Datos Personales podrán ser transferidos para cumplimentar las
          finalidades previstas en el presente Aviso de Privacidad, únicamente
          a: (i) terceros relacionados y/o aliados comerciales de Ridian; (ii)
          autoridades judiciales, mexicanas y extranjeras, con la finalidad de
          dar cumplimiento a la Ley, legislación aplicable a Ridian, el Sitio y
          la Plataforma, notificaciones, requerimientos u oficios de carácter
          judicial; (iii) a proveedores de servicios de internet sobre la cual
          esté montada la infraestructura tecnológica Ridian; y/o (iv) a
          proveedores de servicios de soporte técnico de la Plataforma. En caso
          de realizar alguna transferencia de sus Datos Personales, en los que
          se requiera su consentimiento, se lo informaremos a efecto de recabar
          el mismo. En todos los casos, Ridian comunicará el presente Aviso de
          Privacidad a estos terceros y se asegurará a través de la firma de
          convenios y/o la adopción de otros documentos vinculantes, que dichos
          terceros mantengan las medidas de seguridad administrativas, técnicas
          y físicas necesarias para resguardar sus Datos Personales, así como
          que dichos terceros únicamente utilicen sus Datos Personales para las
          finalidades para los cuales fueron recabados. Ridian no transferirá
          sus Datos Personales a terceros no relacionados con Ridian, sin su
          consentimiento previo.
        </p>
        <p className="py-4 pt-8 font-semibold">
          MEDIOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE SUS DERECHOS ARCO
        </p>
        <p className="pb-4">
          Usted, como titular de los Datos Personales proporcionados a Ridian,
          podrá solicitar en cualquier momento, el ejercicio de sus derechos de
          acceso, rectificación, cancelación u oposición (los &quot;Derechos
          ARCO&quot;) de sus Datos Personales, consistentes en: (i) acceder a
          sus Datos Personales y a los detalles del tratamiento de los mismos;
          (ii) rectificar sus Datos Personales en caso de ser inexactos o
          incompletos; (iii) cancelar sus Datos Personales cuando considere que
          no se requieren para alguna de las finalidades señaladas en este Aviso
          de Privacidad, estén siendo utilizados para finalidades no consentidas
          o haya finalizado su relación contractual o de servicio u otra con
          Ridian; y (iv) oponerse al tratamiento de sus Datos Personales para
          fines específicos. Para tal fin, usted deberá seguir el proceso de
          presentar su petición por escrito a Ridian en el Domicilio, o bien,
          enviar su petición al Correo Electrónico, según sea aplicable, la cual
          deberá contener, como mínimo, la siguiente información: (a) su nombre
          completo y domicilio, u otro medio idóneo para comunicarle la
          respuesta a su solicitud; (b) los documentos que acrediten su
          identidad o, en su caso, la de su representante legal; (c) la
          descripción clara y precisa de los Datos Personales respecto de los
          que se busca ejercer alguno de los derechos antes mencionados; y (d)
          cualquier otro elemento o información que facilite la localización de
          los Datos Personales, así como cualquier otro documento requerido por
          la regulación actual en el momento de presentar la solicitud. Usted
          también podrá solicitar al Correo Electrónico mayor información sobre
          el procedimiento para ejercer sus Derechos ARCO.
        </p>
        <p className="py-4">
          Ridian dará respuesta a su solicitud y resolverá la misma en los
          términos y plazos establecidos en la Ley. No obstante, usted podrá
          obtener más información acerca del estado que guarda su solicitud y
          del plazo de respuesta de la misma, contactando a Ridian o enviando su
          petición al Correo Electrónico, donde además podrán atender cualquier
          aclaración o duda que pudiera tener respecto al tratamiento de sus
          Datos Personales y el ejercicio de sus Derechos ARCO.
        </p>
        <p className="py-4 pt-8 font-semibold">
          REVOCACIÓN DEL CONSENTIMIENTO; LIMITACIÓN DE USO Y DIVULGACIÓN DE LOS
          DATOS PERSONALES
        </p>
        <p className="pb-4">
          Usted también podrá revocar, en cualquier momento, el consentimiento
          que haya otorgado a Ridian para el tratamiento de sus Datos
          Personales, así como solicitar que se limite el uso y divulgación de
          los mismos, siempre y cuando no lo impida una disposición legal y/o su
          petición no sea procedente en términos de la Ley. Para tal fin, usted
          deberá presentar su solicitud por escrito a Ridian en el Domicilio, o
          bien, enviar su solicitud al Correo Electrónico, según sea aplicable.
          Dicha solicitud deberá cumplir con los mismos requisitos mencionados
          en la Sección anterior. Ridian dará respuesta a su solicitud y
          resolverá la misma en los términos y plazos establecidos en la Ley. No
          obstante, usted podrá obtener más información acerca del estado que
          guarda su solicitud y del plazo de respuesta de la misma, contactando
          a Ridian o enviando su petición al Correo Electrónico, donde además
          podrán atender cualquier aclaración o duda que pudiera tener respecto
          al tratamiento y estos derechos que le corresponden respecto a sus
          Datos Personales. En caso de que sus Datos Personales hubiesen sido
          remitidos con anterioridad a la fecha de revocación del
          consentimiento, y sigan siendo tratados por encargados Ridian, éste
          hará del conocimiento de éstos últimos dicha revocación, para que
          procedan a efectuar lo conducente.
        </p>

        <p className="py-4 pt-8 font-semibold">
          CAMBIOS AL AVISO DE PRIVACIDAD
        </p>
        <p className="pb-10">
          Ridian se reserva el derecho de modificar y/o actualizar este Aviso de
          Privacidad, en alguna o todas sus partes, a su entera discreción, en
          cuyo caso lo comunicará a través de su Sitio y/o la Plataforma.
          Asimismo, Ridian colocará un aviso en un lugar visible del Domicilio.
        </p>
        <small className="">
          Fecha de última actualización: Diciembre 2024
        </small>
      </div>
    </div>
  );
};

const PolicyEN = () => {
  return (
    <div className="overflow-auto h-[600px] text-justify">
      <div>
        <p className="pb-4">
          In compliance with the provisions of the Federal Law on the Protection
          of Personal Data Held by Private Parties, its Regulations, and
          applicable Guidelines (the "Law"), Envia Fondos, S.A.P.I. de C.V.
          ("Ridian"), with registered address at Bosque de Chapultepec, Pedregal
          24, Colonia Molino del Rey, C.P. 11000, Mexico City, Mexico (the
          "Address"), electronic address: https://ridian.io/ (the "Website"),
          owner of the digital platform named "Ridian" (the "Platform") and
          other present and future platforms owned by it, and with the contact
          email contact@ridian.io (the "Email"), makes available the following:
        </p>
        <p className="py-4 pt-8 font-semibold">PRIVACY NOTICE</p>
        <p className="pb-4">
          To ensure the legitimate, controlled, and informed handling of the
          personal data of the users of the Website and Platform (the "Users")
          and Users who decide to create an account and contract any of Ridian's
          services (the "Clients"), and to guarantee their privacy and right to
          informational self-determination when providing us with such data, we
          inform you that Ridian is responsible for the processing and
          protection of your personal data, which will be handled based on the
          principles of legality, consent, information, quality, purpose,
          loyalty, proportionality, and responsibility established in the Law.
        </p>
        <p className="pb-4">
          When Ridian processes personal data of Users and Clients through its
          parent companies and/or affiliates and/or subsidiaries and/or related
          parties ("Related Parties") and/or third parties that, due to their
          commercial relationship with Ridian, have contracted the use of the
          Platform to complement their business proposal, it will always
          disclose the content of this Privacy Notice to them.
        </p>
        <p className="py-4 pt-8 font-semibold">PERSONAL DATA WE COLLECT</p>
        <p className="pb-4">
          Ridian requests and collects personal data of its Users and Clients
          ("Personal Data") through access, registration, and profile creation
          on the Website and/or Platform, and/or via email, and/or through
          physical or electronic forms or surveys, as appropriate to the
          services.
        </p>
        <p className="pb-4">
          In all cases, the collection of Personal Data by Ridian is conducted
          in good faith and for the purposes outlined here. Therefore, it is
          presumed that the Personal Data provided by their owners is truthful
          and complete, making the owner responsible for the data they provide.
        </p>
        <ul className="pb-4">
          <li className="ps-10">
            <ol className="">
              A. The only Personal Data collected from Users when using the
              Website and/or Platform include the IP address (Internet Protocol)
              of their electronic device and the URL from which the Users'
              searches originate (whether on the Website or not), the URLs they
              subsequently access (whether on the Website or not), the browser
              used, pages visited, searches made, posts published, expressed
              commercial preferences, and messages published.
            </ol>
            <ol>
              B. The Personal Data collected from Clients to provide the
              services available on the Website and/or Platform (the "Services")
              include:
              <ol className="list-decimal ps-10">
                <li>Full name; </li>
                <li>Email address; </li>
                <li>Landline and mobile phone number; </li>
                <li>Physical address; </li>
                <li>Details contained in their official ID; </li>
                <li>Bank account details; </li>
                <li>Information included in their proof of tax status.</li>
              </ol>
            </ol>
          </li>
        </ul>
        <p className="pb-4">
          We also inform you that we do not collect sensitive Personal Data from
          either Users or Clients.
        </p>
        <p className="py-4 pt-8 font-semibold">
          PURPOSES FOR THE PROCESSING OF PERSONAL DATA
        </p>
        <p className="py-4 pt-8 font-semibold">FOR USERS</p>
        <p className="pb-4">
          a) To consolidate the catalog of services and procedures offered by
          Ridian to Users together with its business partners through the
          Platform. b) To help diagnose issues with the Website and Platform and
          resolve them. c) To fully comply with applicable laws, regulations,
          and guidelines, as well as internal policies, procedures, and legal
          obligations applicable to Ridian, the Website, and the Platform.
        </p>
        <p className="py-4 pt-8 font-semibold">FOR CLIENTS</p>
        <p className="pb-4">
          Once a User decides to contract any of Ridian's Services, they
          automatically become a Client, and Ridian will process their Personal
          Data to fulfill the following purposes, which are necessary for the
          existence, maintenance, and fulfillment of the legal relationship
          between the parties:
        </p>
        <p className="pb-4">
          <ol>
            <li>a) To create their profile and account as a "Client". </li>
            <li>
              b) Document the relationship between the parties, the process of
              use, and the selection made or to be made by the Client regarding
              the modules that make up the Platform's Services.
            </li>
            <li>
              c) To create a record of Clients and the Services they use,
              administer, or manage through the Platform.
            </li>
            <li>
              d) To provide Services directly or through Related Parties or
              third parties that, due to their commercial relationship with
              Ridian, have contracted the use of the Platform to complement
              their business proposal.
            </li>
            <li>
              e) To consolidate the catalog of services and procedures offered
              by Ridian to Clients together with its business partners through
              the Platform.
            </li>
            <li>
              f) To track and manage the commercial and legal relationship with
              Ridian's business partners offering Services on the Platform to
              Clients.
            </li>
            <li>g) To communicate with Clients. </li>
            <li>
              h) To help diagnose issues with the Website and Platform and
              resolve them.
            </li>
            <li>
              i) To comply fully with applicable laws, regulations, guidelines,
              internal policies, procedures, and legal obligations applicable to
              Ridian, the Website, and the Platform.
            </li>
          </ol>
        </p>
        <p className="py-4 pt-8 font-semibold">FOR BOTH USERS AND CLIENTS</p>
        <p className="pb-4">
          If you do not object to this processing, Ridian may process your
          Personal Data for any or all of the secondary purposes mentioned
          below:
        </p>
        <p className="pb-4">
          <ol>
            <li>
              a) To generate data indicators so Ridian can make appropriate
              business decisions.
            </li>
            <li>b) To improve, develop, and refine Services. </li>
            <li>
              c) To use "push" technology through the application Ridian uses to
              send notifications with prior authorization from the User or
              Client.
            </li>
            <li>
              d) For marketing, advertising, or commercial prospecting purposes.
            </li>
          </ol>
        </p>
        <p className="pb-4">
          Please note that Ridian will only use your Personal Data for the
          above-mentioned secondary purposes if you have provided your consent.
          If you do not want your Personal Data to be processed for these
          secondary purposes, please send an email to the Email provided here:
          contact@ridian.io, indicating your refusal for the use of your
          Personal Data for the secondary purpose(s) you wish to limit. If you
          do not express your refusal for the processing of your Personal Data
          as indicated above, it will be understood that you have granted your
          consent for their processing.
        </p>
        <p className="pb-4">
          Refusal to use your Personal Data for these secondary purposes will
          not prevent us from providing the Services you request or contract
          with Ridian on the Website and the Platform.
        </p>

        <p className="pb-4">
          Once the purposes for processing your Personal Data have been
          fulfilled, and unless there is a legal provision stating otherwise,
          Ridian will proceed to cancel, delete, and/or destroy the obtained
          Personal Data in accordance with the terms established by the Law.
        </p>
      </div>
    </div>
  );
};

export const AcceptTermsModal = (props: { data: any }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const [mutate, { error, isLoading }] = useAcceptTermsMutation();

  useErrorListener({ error });

  const handleClick = async () => {
    await mutate(undefined).unwrap();
    handleClose();
  };

  const policy = () => {
    if (i18n.language.toLowerCase().startsWith("es")) {
      return PolicyES();
    }
    return PolicyEN();
  };

  return (
    <Modal className="w-screen">
      <Modal.Header handleClose={() => {}} hideClose={true}>
        <div className="flex w-full justify-between">
          <span className="md:text-7 text-6 font-interSemiBold text-black">
            {t("Privacy Policy")}
          </span>
        </div>
        <div className="absolute right-6">
          <SignUpLangSelector />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-2">
          <div className="overflow-auto h-[600px] text-justify">{policy()}</div>
          <div>
            <Button
              variant="contained"
              className="w-full"
              onClick={handleClick}
              disabled={isLoading}
            >
              {t("Accept Terms")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptTermsModal;
