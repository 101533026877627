import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  Button,
  Input,
  errorSnack,
  selectstyles,
  selecttheme,
  Select,
} from "components/Shared";
import { CreateDestinationBankAccountSnack } from "components/Shared/Snacks";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "react-tooltip";

import {
  RouterSupportedDOCountry,
  RouterSupportedFiat,
  RouterTransferMethods,
} from "interfaces/enums";
import { valueLabelMapper } from "utils";
import { getBankOptions } from "utils/getBankOptions";
import CreatableSelect from "react-select/creatable";
import { IRouterCreateBankAccMX } from "interfaces/router";
import { useCreateBankAccountMXRouterMutation } from "api/institutional/router_api";
import { useEffect } from "react";

export const MexicanAccount = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const bankNameOptions = getBankOptions(RouterSupportedDOCountry.MX);

  const { t } = useTranslation();

  const submitAddBankAccount = async (values: IRouterCreateBankAccMX) => {
    try {
      //@ts-ignore
      await addMXBankAccount({
        account_name: values.account_name,
        alias: values.alias,
        bank_name: values.bank_name,
        sub_account_id: values.sub_account_id,
        transfer_method: values.transfer_method,
        rfc: values.rfc,
        fiat: values.fiat,
        clabe: values.clabe,
        country: values.country,
      }).unwrap();

      // Reset Fields
      reset();

      enqueueSnackbar(<CreateDestinationBankAccountSnack />);

      handleClose();
    } catch (error: any) {
      enqueueSnackbar(
        error || t("An error occurred. Please notifiy Ridian."),
        errorSnack,
      );
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isValid, errors },
  } = useFormContext<IRouterCreateBankAccMX>();

  useEffect(() => {
    setValue("transfer_method", RouterTransferMethods.MX_SPID);
    setValue("country", RouterSupportedDOCountry.MX);
    setValue("fiat", RouterSupportedFiat.USD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addMXBankAccount, { isLoading: isAddingBankAccountMX }] =
    useCreateBankAccountMXRouterMutation();

  return (
    <form
      onSubmit={handleSubmit(submitAddBankAccount)}
      className="gap-5 flex flex-col"
    >
      <div>
        <p className="text-4 font-interSemiBold">{t("Alias")}</p>
        <Input
          type="text"
          placeholder={t("Enter the account number")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("alias", {
            required: true,
            onBlur: () => setValue("alias", getValues("alias")?.trim()),
          })}
        />
      </div>

      {/* Transfer Type */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Transfer Type")}</p>
        <Select
          defaultValue={valueLabelMapper(["SPID"])}
          options={valueLabelMapper(["SPID"])} //[{label: , value: }]
          placeholder={t("Select an option")}
        />
      </div>

      {/* Bank Name */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Bank Name")}</p>
        <CreatableSelect
          theme={selecttheme}
          styles={selectstyles}
          options={valueLabelMapper(bankNameOptions)} //[{label: , value: }]
          onChange={(e: any) => {
            setValue("bank_name", e.value);
          }}
          formatCreateLabel={(prop) => `${t("Bank")}: ${prop}`}
          placeholder={t("Select an option")}
        />
      </div>

      {/* RFC */}
      <div>
        <p className=" text-4 font-interSemiBold">RFC</p>
        <Input
          type="text"
          placeholder={t("Enter the recipient's RFC")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("rfc", {
            required: {
              message: t("RFC must not be empty"),
              value: true,
            },
            onBlur: () => setValue("rfc", getValues("rfc")?.trim() ?? ""),
          })}
        />
      </div>

      {/* Number */}
      <div>
        <p className=" text-4 font-interSemiBold">CLABE</p>
        <Input
          type="text"
          placeholder={t("Enter the recipient's CLABE")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("clabe", {
            required: {
              message: t("CLABE must not be empty"),
              value: true,
            },
            maxLength: {
              message: t("CLABE must be 18 digits long"),
              value: 18,
            },
            minLength: {
              message: t("CLABE must be 18 digits long"),
              value: 18,
            },
            onBlur: () => setValue("clabe", getValues("clabe")?.trim() ?? ""),
          })}
        />
      </div>

      <div>
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          type="submit"
          disabled={isAddingBankAccountMX || !isValid}
        >
          {isAddingBankAccountMX
            ? t("Adding Destination Account")
            : t("Add Destination Account")}
        </Button>
        <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
        {/* </div> */}
      </div>
    </form>
  );
};
