import {
  RouterSupportedFiat,
  RouterSupportedDOCountry,
  currencyOptions,
} from "interfaces/enums";
import { useEffect, useState } from "react";

export const useFindTransferMethodsByFiatAndCountry = ({
  fiat,
  country,
}: {
  fiat: string;
  country: string;
}) => {
  const transferMethods = new Set<string>();

  Object.values(currencyOptions).forEach((options) => {
    options.forEach((option) => {
      if (option.destination === fiat && option.country === country) {
        transferMethods.add(option.transferMethod);
      }
    });
  });

  return { transferMethods: [...transferMethods] };
};
export const useGetFiatByCountry = ({ country }: { country: string }) => {
  const destinations = new Set<string>();

  Object.values(currencyOptions).forEach((options) => {
    options.forEach((option) => {
      if (option.country === country) {
        destinations.add(option.destination);
      }
    });
  });

  return { destinations: [...destinations] };
};

const calculate = ({
  ccy_source,
  ccy_dest,
  country_dest,
}: {
  ccy_dest: RouterSupportedFiat;
  ccy_source: RouterSupportedFiat;
  country_dest: RouterSupportedDOCountry;
}) => {
  const sourceCurrencies = Object.keys(currencyOptions).map(
    (k) => k,
  ) as Array<RouterSupportedFiat>;

  const validDestCurrencies = currencyOptions[ccy_source];

  const destCurrencies = [
    ...new Set(validDestCurrencies?.map((p) => p.destination)),
  ];

  const validCountryOptions = [
    ...new Set(
      validDestCurrencies
        .filter((s) => s.destination === ccy_dest)
        .map((p) => p.country),
    ),
  ];

  const transferMethods = [
    ...new Set(
      validDestCurrencies
        .filter((s) => s.country === country_dest)
        .filter((s) => s.destination === ccy_dest)
        .map((p) => p.transferMethod),
    ),
  ];

  const defaultCountry = validCountryOptions[0];

  const defaultDestination = destCurrencies[0];

  const defaultTransfer = transferMethods[0];

  return {
    defaultCountry,
    defaultDestination,
    defaultTransfer,
    transferMethods,
    validCountryOptions,
    validDestCurrencies,
    sourceCurrencies,
    destCurrencies,
  };
};

export const useCurrencyForm = ({
  ccy_source,
  ccy_dest,
  country_dest,
  cb,
}: {
  ccy_dest: RouterSupportedFiat;
  ccy_source: RouterSupportedFiat;
  country_dest: RouterSupportedDOCountry;
  cb: any;
}) => {
  const [state, setState] = useState(
    calculate({ ccy_dest, ccy_source, country_dest }),
  );

  useEffect(() => {
    const rest = calculate({ ccy_dest, ccy_source, country_dest });
    setState(rest);
    cb({ ...rest, ccy_dest, ccy_source, country_dest });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccy_dest, ccy_source, country_dest]);

  return state;
};
