import { institutionalApi } from "./index";
import {
  IDestinationBankAccountUSRouter,
  IGetCcyConversionOrdersRequest,
} from "interfaces";
import {
  RouterSupportedDOCountry,
  RouterSupportedFiat,
  SyntaticRouterSupportedFiat,
} from "interfaces/enums";

import {
  IRouterQuoteRequest,
  IRouterQuoteResponse,
  IRouterOrder,
  IRouterCreateOrderRequest,
  IRouterDepositDetailsRequest,
  IRouterCreatePayoutRequest,
  IRouterOrderRequest,
  IRouterSubAccount,
  IRouterCreateBankAccUs,
  IRequestRouterGetBankAcc,
  IRouterOrderOriginal,
  IRouterCreateBankAccMX,
} from "interfaces/router";
import {
  removeUndefinedValues,
  transformErrorResponse,
  transformToValidFiat,
} from "utils";

export const ccyConversionOrdersrouter = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getRouterQuote: builder.mutation<IRouterQuoteResponse, IRouterQuoteRequest>(
      {
        query: (params: IRouterQuoteRequest) => ({
          url: `/router/quote?${new URLSearchParams(
            removeUndefinedValues({
              ...params,
              ccy_source: transformToValidFiat(params.ccy_source as any),
            } as IRouterQuoteRequest) as any,
          ).toString()}`,
          method: "GET",
        }),
        transformErrorResponse,
        transformResponse: (response: IRouterQuoteResponse) => {
          return {
            ...response,
            ccy_source:
              response.country_dest === RouterSupportedDOCountry.US &&
              response.ccy_source === RouterSupportedFiat.USD
                ? SyntaticRouterSupportedFiat.MexDolar
                : (response.ccy_source as any),
          };
        },
      },
    ),
    getRouterOrders: builder.query<
      Array<IRouterOrder>,
      IGetCcyConversionOrdersRequest
    >({
      query: (params) => ({
        url: `/router?${new URLSearchParams(
          removeUndefinedValues(params) as any,
        )}`,
        method: "GET",
      }),
      providesTags: ["RouterOrders"],
      transformErrorResponse,
      transformResponse: (response: Array<IRouterOrderOriginal>) => {
        return response.map((r) => ({
          ...r,
          ccy_source:
            r.country_dest === RouterSupportedDOCountry.US &&
            r.ccy_source === RouterSupportedFiat.USD
              ? SyntaticRouterSupportedFiat.MexDolar
              : r.ccy_source,
          qty_dest: String(r.qty_dest),
          qty_source: String(r.qty_source),
        })) as Array<IRouterOrder>;
      },
    }),
    getRouterOrder: builder.query<IRouterOrder, IRouterOrderRequest>({
      query: (params) => ({
        url: `/router/order/${params.order_id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "RouterOrder", id: arg.order_id },
      ],
      transformErrorResponse,
      transformResponse: (response: IRouterOrderOriginal) => {
        return {
          ...response,
          ccy_source:
            response.country_dest === RouterSupportedDOCountry.US &&
            response.ccy_source === RouterSupportedFiat.USD
              ? SyntaticRouterSupportedFiat["MexDolar"]
              : response.ccy_source,
          qty_dest: String(response.qty_dest),
          qty_source: String(response.qty_source),
        } as IRouterOrder;
      },
    }),
    getRouterOrderDepositDetails: builder.query<
      string,
      IRouterDepositDetailsRequest
    >({
      query: (params) => ({
        url: `/router/order/deposit/bank_details?order_id=${params.order_id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "RouterDepositDetail", id: arg.order_id },
      ],
      transformErrorResponse,
    }),
    createRouterPayout: builder.mutation<string, IRouterCreatePayoutRequest>({
      query: (body) => ({
        url: `/router/order/payout`,
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
    confirmTransferSPID: builder.mutation<string, { order_id: string }>({
      query: (body) => ({
        url: `/router/order/spid/confirm_transfer`,
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
    createRouterOrder: builder.mutation<
      IRouterOrder,
      IRouterCreateOrderRequest
    >({
      query: (body) => ({
        url: `/router/order`,
        method: "POST",
        body: removeUndefinedValues(body),
      }),
      transformErrorResponse,
    }),
    // Get US Destination Bank Accounts
    getBankAccountsRouter: builder.query<
      Array<IDestinationBankAccountUSRouter>,
      IRequestRouterGetBankAcc
    >({
      query: (params) => ({
        url: `/router/bank_account?${new URLSearchParams(
          removeUndefinedValues(params) as any,
        )}`,
        method: "GET",
      }),
      providesTags: [{ type: "RouterAccounts" }],
      transformErrorResponse,
    }),
    // Get Non US Destination Bank Accounts
    // getBankAccountsNonUSRouter: builder.query<
    //   Array<IDestinationBankAccountMexicanRio>,
    //   IRequestRioCreateBankAccNonUs
    // >({
    //   query: (params) => ({
    //     url: `/router/bank_account/non_us?${new URLSearchParams(
    //       removeUndefinedValues(params) as any,
    //     )}`,
    //     method: "GET",
    //   }),
    //   providesTags: [{ type: "RouterNonUsAccounts" }],
    //   transformErrorResponse,
    // }),
    // Sub Accounts
    getRouterSubAccounts: builder.query<Array<IRouterSubAccount>, void>({
      query: (params) => ({
        url: `/router/broker/sub_accounts`,
        method: "GET",
      }),
      providesTags: [{ type: "RouterSubAccounts" }],
      transformErrorResponse,
    }),
    createBankAccountMXRouter: builder.mutation<string, IRouterCreateBankAccMX>(
      {
        query: (body) => ({
          url: "/router/bank_account",
          method: "POST",
          body,
        }),
        transformErrorResponse,
        invalidatesTags: [
          { type: "RouterMXAccounts" },
          { type: "RouterAccounts" },
          { type: "RouterUsAccounts" },
        ],
      },
    ),
    // Post US Destination Bank Account
    createBankAccountRouter: builder.mutation<string, IRouterCreateBankAccUs>({
      query: (body) => ({
        url: "/router/bank_account",
        method: "POST",
        body,
      }),
      transformErrorResponse,
      invalidatesTags: [
        { type: "RouterMXAccounts" },
        { type: "RouterAccounts" },
        { type: "RouterUsAccounts" },
      ],
    }),
    simulateRouterDeposit: builder.mutation<
      string,
      { order_id: string; ccy: RouterSupportedFiat; qty: string }
    >({
      query: (body) => ({
        url: "/router/simulate/user_transfer",
        method: "POST",
        body: { ...body, ccy: transformToValidFiat(body.ccy as any) },
      }),
      transformErrorResponse,
    }),
    simulateRouterTransferToLp: builder.mutation<string, { order_id: string }>({
      query: (body) => ({
        url: "/router/simulate/transfer_to_lp",
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
  }),
});

export const {
  usePrefetch,
  useCreateBankAccountRouterMutation,
  useCreateRouterOrderMutation,
  useCreateRouterPayoutMutation,
  useGetBankAccountsRouterQuery,
  useGetRouterQuoteMutation,
  useGetRouterOrderQuery,
  useCreateBankAccountMXRouterMutation,
  useGetRouterOrdersQuery,
  useGetRouterSubAccountsQuery,
  useSimulateRouterDepositMutation,
  useSimulateRouterTransferToLpMutation,
  useConfirmTransferSPIDMutation,
} = ccyConversionOrdersrouter;
