import React from "react";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ children, className = "" }) => (
  <div
    className={`${className} w-[35rem] pb-4 max-w-[100%] bg-gray-dark1300 overflow-auto`}
  >
    {children}
  </div>
);

const Header = ({ children, handleClose, hideClose = false }) => (
  <DialogContent className="border-b-[1px] border-gray-light100 p-6 flex justify-between sm:min-w-[480px] items-center">
    {children}
    <button onClick={handleClose} hidden={hideClose}>
      <CloseIcon color="white" />
    </button>
  </DialogContent>
);

const Body = ({ children }) => (
  <DialogContent className="p-6 text-black flex flex-col gap-6">
    {children}
  </DialogContent>
);

const Footer = ({ children }) => (
  <DialogContent className="p-6">{children}</DialogContent>
);

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
