import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Button,
} from "@mui/material";
import { forwardStep } from "app/slices/globalSlice";
import { EmptyComponent } from "components/Shared";
import { TextareaAutosize } from "components/Shared/Textarea";
import { useKeyboardShortcut } from "hooks/useKeyboardShortcut";
import { IOnboardingDraft } from "interfaces";
import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const revenueOptions = [
  "Business Revenue",
  "Invesment Proceeds",
  "Business Loans",
  "Sale of Assets",
  "Equity Funding",
  "Legal Settlement / Tax Refund",
  "Grants",
  "Pension Retirement",
  "Third Party Funds",
];

const annualRevenues = [
  "$0 - $99,999 USD",
  "$100,000 - $999,999 USD",
  "$1 million - $9 million USD",
  "$10 million - $49 million USD",
  "$50 million - $249 million USD",
  ">$250 million USD",
];
export const RevenueForm = () => {
  const { t } = useTranslation();

  const { setValue, control, watch } = useFormContext<IOnboardingDraft>();

  const estimated_annual_revenue_range = watch(
    "estimated_annual_revenue_range",
  );
  const estimated_monthly_volume_range = watch(
    "estimated_monthly_volume_range",
  );
  const client_kyb_process = watch("client_kyb_process");

  const moves_client_funds = watch("moves_client_funds");

  useKeyboardShortcut({
    key: "Enter",
    onKeyPressed: () => {
      step2Btn.current?.click();
    },
  });

  const step2Btn = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();

  const revenueIsCompleted = () => {
    if (estimated_annual_revenue_range && estimated_monthly_volume_range) {
      if (moves_client_funds) {
        if (!client_kyb_process) {
          return false;
        }
        return true;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!moves_client_funds) {
      setValue("client_kyb_process", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moves_client_funds]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="source_of_funds-label">
          {t("Source of funds")}
        </InputLabel>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              labelId={"source_of_funds-label"}
              label={t("Source of funds")}
            >
              {revenueOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
          )}
          name={"source_of_funds"}
          control={control}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="estimated_annual_revenue_range-label">
          {t("Estimated Annual Revenue Range")}
        </InputLabel>
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              labelId={"estimated_annual_revenue_range-label"}
              label={t("Estimated Annual Revenue Range")}
            >
              {annualRevenues.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
          )}
          name={"estimated_annual_revenue_range"}
          control={control}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel id="moves_client_funds-label">
          {t(
            "Will you be transmitting funds on behalf of your customers -- if so, does your business conduct compliance screening, including KYC,KYB or AML?",
          )}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(e) => {
                setValue(
                  "moves_client_funds",
                  e.currentTarget.value === "true",
                );
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          )}
          name={"moves_client_funds"}
          control={control}
        />
      </FormControl>

      <EmptyComponent value={!!moves_client_funds}>
        <FormControl>
          <Controller
            control={control}
            name="client_kyb_process"
            rules={{ required: moves_client_funds }}
            render={({ field }) => (
              <TextareaAutosize
                minRows={5}
                placeholder={t(
                  "Explain how do you transmit funds and how do you do the screening process",
                )}
                {...field}
              />
            )}
          />
        </FormControl>
      </EmptyComponent>

      <FormControl>
        <Controller
          control={control}
          name="estimated_monthly_volume_range"
          rules={{ required: true }}
          render={({ field }) => (
            <TextareaAutosize
              minRows={1}
              placeholder={t(
                "Please provide your expected monthly trade volume with Ridian in USD",
              )}
              {...field}
            />
          )}
        />
      </FormControl>

      <Button
        variant="contained"
        disabled={!revenueIsCompleted()}
        onClick={() => dispatch(forwardStep())}
        ref={step2Btn}
      >
        {t("Continue")}
      </Button>
    </>
  );
};
